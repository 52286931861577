@font-face {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 200 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(./fonts/Inconsolata.ttf);
}

* {
  margin: 0;
  padding: 0;
  border: none;
  font-family: Inconsolata, monospace;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  overflow: visible;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
